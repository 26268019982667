import './chart.css';
import { useEffect,useState } from 'react';
import {useRef} from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Contacts from '../contacts/Contacts';


const Chart=()=>{
    const [pageViewCount, setPageViewCount] = useState([]);
    useEffect(() => {
       fetch('https://personal-portfolio-nani-9edb64cbd82b.herokuapp.com/count')
          .then((res) => res.json())
          .then((data) => {
             //console.log(data);
             setPageViewCount(data);
             
          })
          .catch((err) => {
             //console.log(err.message);
          });
    }, []);

    const sortByDate = pageViewCount => {
        const sorter = (a, b) => {
           return new Date(a.date).getTime() - new Date(b.date).getTime();
        }
        pageViewCount.sort(sorter);
     };
     sortByDate(pageViewCount);
     //console.log(pageViewCount);
   const date=pageViewCount.map((date)=>date.date)
   //console.log(date)
  
   const count=pageViewCount.map((count)=>count.count)
  //console.log(count)
    return(
      <>
       <div className="chartcontainer">
         <div className='chartleft'>
            <div className='gridchart'>
            <div className='charttitle'>Y'all visiting my page?<br/>The graph doesnt lie!!!</div>
            <ResponsiveContainer  className="rescontainer" width='99%' aspect={3/1.5}>
            <AreaChart width={730} height={250} data={pageViewCount}
  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
  <defs> 
    <linearGradient id="count" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="yellowgreen" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="yellowgreen" stopOpacity={0}/>
    </linearGradient>
    
  </defs>
  <XAxis dataKey="date" />
  <YAxis  />
  <CartesianGrid strokeDasharray="3 3"  stroke='black'/>
  <Tooltip />
  <Area type="monotone" dataKey="count" stroke="yellowgreen" fillOpacity={1} fill="url(#count)" />
</AreaChart>
            </ResponsiveContainer>
            </div>
            </div>
            <div className='chartright'>
            <Contacts/>
            </div>

        </div>
        </>

        )
    
    
}





export default Chart