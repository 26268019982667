import './summary.css';
import {init} from 'ityped';
import { useEffect, useRef } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaReact } from "react-icons/fa";
import {BsGithub} from "react-icons/bs"
import Radial from '../radialexp/Radial';
import {BsLinkedin} from "react-icons/bs";
import {BsInstagram} from "react-icons/bs";
import {MdEmail} from "react-icons/md";
import resumepdf from '../../images/niveresume.pdf';
import {MdDownload} from 'react-icons/md';
import {MdFileDownloadDone} from 'react-icons/md'
import { useState } from 'react';



const socialMediaHandlesLinks = {
    gitHub: 'https://github.com/Nivedhanaren',
    instagram: 'https://instagram.com/nivedhavirushabadoss?igshid=MzMyNGUyNmU2YQ==',
    linkedin: 'https://www.linkedin.com/in/nivedha-virushabadoss',
  }



const Summary=()=>{
    const [style, setStyle] = useState("resumeitem");
    const downloadFile = () => {
        window.location.href = resumepdf
      }
    const changeStyle = () => {
      //console.log("you just clicked");
    
      setStyle("resumeitemclicked");
    };
    const textRef = useRef();

    useEffect(() => {
        init(textRef.current, {
          showCursor: true,
          backDelay: 1500,
          backSpeed:60,
          strings: ["Frontend", "Backend", "FullStack"],
          loop:false,
        });
      }, []);
    const iconclick=()=>{
            //console.log("hdksj")
    }
    const resumeclick=()=>{
        //console.log("jfnsk")
    }
    return(
       <div className='intro' id="summary"> 
       
            <div className="left">
                <div className="summarywrapper">
                    <h2>
                        Hi there, its me
                    </h2>
                    <div className='drilogo'>
                    <b>N<span>iv</span>ed<span>h</span>a</b>
                    </div>
                    <h3>
                        <span className='devtitle' ref={textRef}></span> dev
                    </h3>
                    <p>
                    Passionate fullstack developer focusing on creating user-centered and intuitive <br/>digital experience for clients
                    </p>
                    <div className='summarymedia'>
                    <div className='smediaicons'>
                        <a target='_blank' href={socialMediaHandlesLinks.gitHub} className='mediagithub'><BsGithub size={25}/></a>
                        <a target='_blank' href={socialMediaHandlesLinks.linkedin} className='medialinkedin'><BsLinkedin size={25}/></a>
                        <a target='_blank' href={socialMediaHandlesLinks.instagram} className='mediainsta'><BsInstagram size={25}/></a>
                        <a target='_blank'  onClick={() => window.location = 'mailto:nani042521@gmail.com'}
                             href={socialMediaHandlesLinks.instagram} className='mediamail'><MdEmail size={25}/></a>
                    </div>
                    <a className='resumecontainer' download='' href={resumepdf}>
                        <div className={style} onClick={changeStyle}>
                        <a className='itemlink' download='' href={resumepdf} >Resume</a>
                        {/* <a className='itemlink'>Resume</a> */}
                            <div className='resumedwlbutton'>
                                <a className='downloadicon' download='' href={resumepdf}>
                            <MdDownload  size={25}/></a>
                            </div>
                            <div className='dwldone'>
                            <MdFileDownloadDone size={25}/>
                            </div>
                        </div>
                    </a>
                    </div>
                </div>
            </div>
            <div className="right">
                <Radial/>
            </div>
       </div>
    )
}
export default Summary