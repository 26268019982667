import './projects.css';
import {MdOutlineKeyboardArrowLeft} from 'react-icons/md';
import {MdOutlineKeyboardArrowRight} from 'react-icons/md';
import portfolioimage from '../../images/Portfoliosite.jpg'
import fooddeliveryapp from '../../images/Foodapp.jpg';
import adminpanel from "../../images/Adminpanel.jpg";
import hotelapp from '../../images/Hotelbooking.jpg';
import techapp from '../../images/Nfinitytech.jpg'
import { useState } from 'react';

const Projects=()=>{

    const socialMediaHandlesLinks = {
        gitHub: 'https://github.com/Nivedhanaren',
        
      }

    const[togglearrowleft,setToggleArrowleft]=useState(true)
    const[togglearrowright,setToggleArrowright]=useState(false)

    const getBox=()=>{
        return document.querySelector('.wrapper')
    }
    const prev=()=>{
        const box=getBox();
        const width=box.clientWidth;
        box.scrollLeft=box.scrollLeft-width
        //console.log(box)
        if(box.scrollLeft==0){
            setToggleArrowleft(true)
        }

    }
    const next=()=>{
        const box=getBox();
        const width=box.clientWidth;
        box.scrollLeft=box.scrollLeft+width
        if(box.scrollLeft!==0){
            setToggleArrowleft(false)
          
        }
        
    }
    return(
        <div className='pcontainer'>
        <div className='ptitle' id="projects"><h1 className='projecttitle'>Projects</h1></div>
        <div className='projectsbody' >
            {/* <h1 className='projecttitle'>Projects</h1> */}
           {!togglearrowleft && <div className='arrowicon arleft' ><MdOutlineKeyboardArrowLeft size={100} onClick={prev}/></div>}
        <div className='wrapper'>
            {/* <div className='arrowicon' ><MdOutlineKeyboardArrowLeft size={80} onClick={prev}/></div> */}
                <div className='projectcard'>
                    <img src={fooddeliveryapp}>
                    </img>
                    <div className='projectinfo'>
                        <h1>Food Delivery App</h1>
                        <p>
                            A mock food delivery app focusing on UI for food menu and adding them to cart
                        </p>
                        <a target='_blank' href={socialMediaHandlesLinks.gitHub}  className='prolink'>View Github</a>
                    </div>
                </div>
                <div className='projectcard'>
                    <img src={adminpanel}>
                    </img>
                    <div className='projectinfo'>
                        <h1>Admin Panel</h1>
                        <p>
                            This project is about creating and managing Admin/user login, dashboard:MERN

                        </p>
                        <a target='_blank' href={socialMediaHandlesLinks.github} className='prolink'>View Github</a>
                    </div>
                </div>

                <div className='projectcard'>
                    <img src={portfolioimage}>
                    </img>
                    <div className='projectinfo'>
                        <h1>Portfolio</h1>
                        <p>
                          Attempt of digital resume with front-end touch using MERN!
                        </p>
                        <a target='_blank' href={socialMediaHandlesLinks.github} className='prolink'>View Github</a>
                    </div>
                </div>
               
                <div className='projectcard'>
                    <img src={hotelapp}>
                    </img>
                    <div className='projectinfo'>
                        <h1>Hotel Booking</h1>
                        <p>
                            A hotel website for booking using react and fetching data from Mongodb
                        </p>
                        <a target='_blank' href={socialMediaHandlesLinks.github} className='prolink'>View Github</a>
                    </div>
                </div>
                <div className='projectcard'>
                    <img src={techapp}>
                    </img>
                    <div className='projectinfo'>
                        <h1>Nani Tech-IT solutions</h1>
                        <p>
                            Simple website about an IT company with job portal,login and other capabilities:MERN stack
                        </p>
                        <a target='_blank' href={socialMediaHandlesLinks.github} className='prolink'>View Github</a>
                    </div>
                </div>
                {/* <div className='arrowicon'><MdOutlineKeyboardArrowRight size={80} onClick={next}/></div> */}
            </div>
            {togglearrowleft && <div className='arrowicon arright' ><MdOutlineKeyboardArrowRight size={80} onClick={next}/></div>}
        </div>
        </div>
    )
}

export default Projects
