import './App.css';
import { useEffect } from 'react';
import Chart from './components/chart/Chart';
import Education from './components/education/Education';
import Navbar from './components/navbar/Navbar';
import Projects from './components/projects/Projects';
import Radial from './components/radialexp/Radial';
import Summary from './components/summary/Summary';
import ParticlesBackground from './components/ParticlesBackground';

function App() {
  const ranString = (Math.random() + 1).toString(36).substring(7);
  //const viewdate = new Date();
  //console.log(viewdate) 

//dt.setTime(dt.getTime()+dt.getTimezoneOffset()*60*1000);
//console.log(dt);

//var offset = -300; //Timezone offset for EST in minutes.
//var estDate = new Date(dt.getTime() + offset*60*1000);
//console.log(estDate); 

useEffect(() => {
  const userId={ranString}
  const dt=new Date()
    let result =  fetch(
    'https://personal-portfolio-nani-9edb64cbd82b.herokuapp.com/count', {
        method: "post",
        body: JSON.stringify(userId),
        headers: {
            'Content-Type': 'application/json'
        }
    })
  
// empty dependency array means this effect will only run once (like componentDidMount in classes)
}, []);

  return (
    <div className='App'>
      <ParticlesBackground className='particlebg'/>
     <Navbar/>
     <Summary className="summary"/>
      <Projects className="projectscss"/>
      <Education />
      <Chart/>
    </div>
  );
}

export default App;
