import './education.css';
import {FaUniversity} from 'react-icons/fa';
import {PiCertificateDuotone} from 'react-icons/pi';
import {MdWork} from 'react-icons/md'
import { useEffect } from 'react';
const Education=()=>{
    function reveal() {
        var reveals = document.querySelectorAll(".timeline");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }
      
      window.addEventListener("scroll", reveal);
      

    useEffect(()=>{
        //console.log('hello')
    }
    )
    return (
        <div className='edu' id="experience"><h1 className='edutitle'>Professional Development/Experience</h1>
        <div className='timeline' >
            
            <div className="educontainer leftcontainer">
            <div className='eduicon' ><PiCertificateDuotone size={30}></PiCertificateDuotone></div>

                <div className="textbox">
                    <h2>Codedamn / UDEMY - Full stack development</h2>
                    <small>Jan 2023-</small>
                    <p>Courses helped me get proficiency in full stack dev, fueled my curiosity to do projects implementing skills learned.</p>
                    <span className='leftarrow'></span>
                </div>
            </div>

            <div className="educontainer rightcontainer">
            <div className='eduicon' ><MdWork size={30}></MdWork></div>

                <div className="textbox">
                    <h2>Senior System Engineer - Infosys/Verizon</h2>
                    <small>Jun 2020 - Dec 2020</small>
                    <p>Started learning react framework first time, intrigued by its simplicity in managing components and creating more user centered front end </p>
                    <span className='rightarrow'></span>
                </div>
            </div>

            <div className="educontainer leftcontainer">
            <div className='eduicon' ><MdWork size={30}></MdWork></div>

                <div className="textbox">
                    <h2>System Engineer - Infosys/Verizon</h2>
                    <small>Aug 2018 - Jun 2020</small>
                    <p>First professional experience of software development in structured environment, used .NET framework to manage and succeed client expectation</p>
                    <span className='leftarrow'></span>
                </div>
            </div>

            <div className="educontainer rightcontainer">
            <div className='eduicon' ><FaUniversity size={30}></FaUniversity></div>

                <div className="textbox">
                    <h2>Anna University - Bachelor of Engineering</h2>
                    <small>Oct 2014-May 2018</small>
                    <p>Major in Electronics and communications engineering which helped me learn grassroots of software development </p>
                    <span className='rightarrow'></span>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Education
