import "./radial.css"
import { FaReact } from "react-icons/fa";
import {BiLogoHtml5} from "react-icons/bi";
import {FaCss3Alt} from "react-icons/fa";
import {BiLogoMongodb} from "react-icons/bi";
import {FaNodeJs} from "react-icons/fa";
import {SiExpress} from "react-icons/si";
import {BiLogoTypescript} from "react-icons/bi"
import {AiOutlineCloudSync} from "react-icons/ai";
import {SiMysql} from "react-icons/si";
import anime from '../../images/nivianimae1.jpeg'

const Radial=()=>{
    return(
        <div className='menubody'>
            <div className='container'>
                <div className='icon'>
                <div   className='imgbox active' style={{'--i':1}}>
                    <span className="menuicon"><FaReact size={40}/>
                    </span>
                    <span className="icontext">React
                    </span>
                </div>
                <div className='imgbox' style={{'--i':2}}>
                    <span className="menuicon"><BiLogoHtml5 size={40}/></span>
                    <span className="icontext">HTML5
                    </span>
                </div>
                <div className='imgbox' style={{'--i':9}}>
                    <span className="menuicon"><FaCss3Alt size={40}/></span>
                    <span className="icontext">CSS3
                    </span>
                </div>
                <div className='imgbox' style={{'--i':4}}>
                    <span className="menuicon"><BiLogoMongodb size={40}/></span>
                    <span className="icontext">Mongo
                    </span>
                </div>
                <div className='imgbox' style={{'--i':3}}>
                    <span className="menuicon"><FaNodeJs size={40}/></span>
                    <span className="icontext">NodeJs
                    </span>
                </div>
                <div className='imgbox' style={{'--i':6}}>
                    <span className="menuicon"><SiExpress size={40}/></span>
                    <span className="icontext">Expressjs
                    </span>
                </div>
                <div className='imgbox' style={{'--i':7}}>
                    <span className="menuicon"><BiLogoTypescript size={40}/></span>
                    <span className="icontext">Typescript
                    </span>
                </div>
                <div className='imgbox' style={{'--i':8}}>
                    <span className="menuicon"><AiOutlineCloudSync size={40}/></span>
                    <span className="icontext">RestApi
                    </span>
                </div>
                <div className='imgbox' style={{'--i':5}}>
                    <span className="menuicon"><SiMysql size={40}/></span>
                    <span className="icontext">Mysql
                    </span>
                </div>

                <div className="imagecontent">
                    <img src={anime} width="250" height="250"></img>
                    </div>
                </div>
            </div>
        </div> 
    )
}
export default Radial