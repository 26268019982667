import { useState,useEffect } from 'react';
import './navbar.css';
import React from 'react';
import {Link} from 'react-scroll';
import nivilogo from '../../images/nivilogo4.mp4';
import {BiMenuAltRight} from 'react-icons/bi';
import {AiOutlineLike} from 'react-icons/ai';
import {AiFillLike} from "react-icons/ai";

const Navbar=()=>{
    const[togglemenu,setToggleMenu]=useState(false)
    const[likeButton,setLikeButton]=useState('likemenubutton')
    const [togglelikebutton,setToggleLikeButton]=useState(false)
    const[likeresult,setLikeResult]=useState(0)

    const changeStyle = () => {
        setLikeButton("like");
    };
    const handlelikecount=()=>{
        setToggleLikeButton(true)
        }

     
        useEffect(() => {
           //console.log(togglelikebutton)
            if (togglelikebutton ===true) {
                // New visit and pageview
               
                updateLikeCounter('type=true');
                
            }
            if(togglelikebutton===false){
                updateLikeCounter('type=');
            }
            //console.log("hello")
            function updateLikeCounter(type) {
                //console.log(type)
                //console.log("abc")
                fetch('https://personal-portfolio-nani-9edb64cbd82b.herokuapp.com/likecount?'+type) // Dynamic request with URL parameter
                  .then(res => res.json())
                  .then(data => {
                    setLikeResult(data.likecounts); // Display pageviews to user
                  })
                } 
           
            },[togglelikebutton]);   
    return(

        <div className="navigationbar">
            <div>
            <video className='logo' width="150" height="200" autoPlay loop muted   playsInline>
                <source src={nivilogo} />
            </video>
            </div>
            <div className="menu" >
                <Link  style={{'--i':1}} activeClass='activemenu' to='summary'  spy={true} smooth={true} offset={-200} duration={500} className="menulist">About</Link>
                <Link style={{'--i':2}} activeClass='activemenu' to='projects'  spy={true} smooth={true} offset={-130} duration={500} className="menulist">Projects</Link>
                <Link style={{'--i':3}}  activeClass='activemenu' to='experience'  spy={true} smooth={true} offset={-130} duration={500} className="menulist">Experience</Link>
                <Link style={{'--i':4}} activeClass='activemenu' to='contacts'  spy={true} smooth={true} offset={-130} duration={500}   className="menulist">Contact</Link>
            </div>
            <div className='likescount'>
           {togglelikebutton?<div className='filllike'>
                <AiFillLike 
                  size={25} onClick={()=>setToggleLikeButton(false)}/>
            </div> : <div className='outlinelike' ><AiOutlineLike onClick={handlelikecount} size={25}/></div>}
            <div className='likeresult'>{likeresult} Likes </div>
            </div>
            {/* <div className={likeButton} onClick={changeStyle}>
               <AiOutlineLike size={30}/>
            </div> */}
            <div className='mobileview' >
            <div>
                <BiMenuAltRight size={50} style={{color:"yellowgreen"}}onClick={()=>setToggleMenu(!togglemenu)}/>
                </div> 
            <div className='likescountmv'>
           {togglelikebutton?<div className='filllike'>
                <AiFillLike 
                  size={25} onClick={()=>setToggleLikeButton(false)}/>
            </div> : <div className='outlinelike' ><AiOutlineLike onClick={handlelikecount} size={25}/></div>}
            <div className='likeresult'>{likeresult} Likes </div>
            </div>
             
            </div>
            <div className="navigationmenu" style={{display: togglemenu?"flex":'none'}}>
                <Link style={{'--i':1}} activeClass='activemenu' to='summary'  spy={true} smooth={true} offset={-100} duration={500} className="listnavmenu"  onClick={()=>setToggleMenu(false)}>About</Link>
                <Link style={{'--i':2}} activeClass='activemenu' to='projects'  spy={true} smooth={true} offset={-50} duration={500} className="listnavmenu"  onClick={()=>setToggleMenu(false)}>Projects</Link>
                <Link style={{'--i':3}} activeClass='activemenu' to='experience'  spy={true} smooth={true} offset={-50} duration={500} className="listnavmenu"  onClick={()=>setToggleMenu(false)}>Experience</Link>
                <Link  style={{'--i':4}} activeClass='activemenu' to='contacts'  spy={true} smooth={true} offset={-50} duration={500} className="listnavmenu"  onClick={()=>setToggleMenu(false)}>Contact</Link>
            </div>
        </div>
       
    )

}

export default Navbar;