import Particles from 'react-tsparticles';
import particleConfig from './config/particles-config';
import { loadFull } from "tsparticles";
import cloudicon1 from '../images/Cloudicon.png';
import cludicon2 from '../images/routericon.png';
import cpticon from '../images/computericon2.png';
import lensicon from '../images/aiicon.png';
import mobicon from '../images/Mobileicon3.png';
import laptopicon from '../images/Usericon2.png';
import usericon from '../images/Usericon3.png';
import storageicon from '../images/Storageicon2.png';
import charticon from '../images/datasecurityicon.png';

export default function ParticlesBackground() {
	const particlesInit = async (main) => {
		//console.log(main);
		await loadFull(main);
	};
	const particlesLoaded = (container) => {
		//console.log(container);
	};
	return (
		<Particles
			
			id="tsparticles"
			init={particlesInit}
			loaded={particlesLoaded}
            
            options={
                {
                    background: {
                    color: {
                        value: "#010101",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 50,
                            duration: 0.8,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 1,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 1600,
                        },
                        value: 50,
                    },
                    opacity: {
                        value: 0.6,
                    },
                    shape: {
                        type: ["images"],
                    
                    
                    images: [
                    {
                        src: cloudicon1,
                        width: 400,
                        height: 400,
                    },
                    {
                        src: cludicon2,
                        width: 400,
                        height: 400,
                    },
                    {
                        src: cpticon,
                        width:400,
                        height:400,
                    },
                    {
                        src: mobicon,
                        width:200,
                        height:200,
                    },
                    {
                        src: laptopicon,
                        width:400,
                        height:400,
                    },
                    {
                        src: lensicon,
                        width:400,
                        height:400,
                    },
                    {
                        src: storageicon,
                        width:400,
                        height:400,
                    },
                    {
                        src: charticon,
                        width:400,
                        height:400,
                    },
                    {
                        src: usericon,
                        width:400,
                        height:400,
                    },
                ],
            },
                    size: {
                        value: { min: 5, max:10 },
                    },
                },
                detectRetina: true,
            }
        }
                
            
		/>
	);
}



