import './contacts.css';
import { useState } from "react";

const Contacts=()=>{
    const [message, setMessage] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage(true);
      };
    return(
        <div className='contacts' id="contacts">
            <h2>Contact Me</h2>
        <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Enter Your Name" ></input>
          <input type="text" placeholder="Email" />
          <textarea placeholder="Message"></textarea>
          <button type="submit">Send</button>
          {message && <span>Thanks, I'll reply ASAP :)</span>}
        </form>

        </div>
    )
}

export default Contacts;